<template>
  <container ref="container" @error="init">
    <!-- 顶部 -->
    <publicHeader></publicHeader>

    <!-- 横幅 -->
    <publicBanner :src="banner"></publicBanner>

    <!-- 面包屑导航 -->
    <breadcrumb :router="router" :nav="breadcrumb"></breadcrumb>

    <!-- 路由 -->
    <router-view></router-view>

    <!-- 底部 -->
    <publicFooter></publicFooter>

    <!-- 联系 -->
    <contact></contact>
  </container>
</template>

<script>
import publicHeader from "@/components/publicHeader.vue";
import publicFooter from "@/components/publicFooter.vue";
import publicBanner from "@/components/publicBanner.vue";
import contact from "@/components/contact.vue";
import breadcrumb from "@/components/breadcrumb.vue";

export default {
  name: "university",
  components: {
    publicHeader,
    publicFooter,
    publicBanner,
    breadcrumb,
    contact,
  },
  computed: {
    router() {
      return [
        "首页",
        "月嫂课堂",
        this.breadcrumb.find((item) => item.route == this.$route.name).name,
      ];
    },
  },
  data() {
    return {
      breadcrumb: [
        {
          name: "看课堂",
          route: "universityService",
        },
        
        {
          name: "揭秘培训",
          route: "universityUnderstand",
        },
        
      ],
      banner: "",
    };
  },
  mounted() {
    // 初始化
    this.init();
  },
  methods: {
    /**
     * 初始化数据
     */
    async init() {
      try {
        // 设置状态
        this.$refs.container.status("init", {
          height: "100vh",
        });

        // 获取数据
        const banner = await this.$axios("/api/mainstation/headImage", {
          params: {
            type: 23,
          },
        });

        // 设置数据
        this.banner = banner.data;

        // 设置状态
        this.$refs.container.status("default");
      } catch (e) {
        // 设置状态
        this.$refs.container.status("error", {
          text: e.message,
          height: "100vh",
        });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
</style>
